<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <PeopleAutocomplete
              label="Pessoa: "
              v-model="selectedPerson"
              :readonly="mod === 'update'"
              class="required mb-4"
            />
          </v-col>
        </v-row>

        <v-row
          ><v-col>
            <v-select
              class="required"
              label="Perfil de acesso"
              :items="accessProfiles"
              v-model="form.AccessProfileId"
              :loading="loadingAccessProfiles"
            >
            </v-select></v-col
        ></v-row>

        <v-row>
          <v-col>
            <v-text-field label="E-mail" class="required" v-model="form.email">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.password"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-2"
              :label="mod === 'update' ? 'Nova senha' : 'Senha'"
              class="input-group--focused required"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.confirmPassword"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-3"
              :label="
                mod === 'update' ? 'Confirmar nova senha' : 'Confirmar senha'
              "
              class="input-group--focused required"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-dialog v-model="successDialog" persistent max-width="450">
          <v-card>
            <v-card-text>
              <div class="text-center pa-4">
                <v-icon large color="success">mdi-check</v-icon>
              </div>

              <div class="text-center pa-3">
                <h3>Dados atualizados com sucesso</h3>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeSuccessDialog">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import formMixin from "@/mixins/formMixin";
import PeopleAutocomplete from "@/components/template/FormElements/People/PeopleAutocomplete.vue";

export default {
  mixins: [formMixin],
  components: { BaseForm, PeopleAutocomplete },
  data: function () {
    return {
      showPassword: false,
      loading: false,
      successDialog: false,
      loadingAccessProfiles: false,
      loadingPeople: false,
      accessProfiles: [],
      actionAfterOperation: null,
      selectedPerson: null,
      form: {
        PersonId: null,
        AccessProfileId: null,
        password: null,
        email: null,
        confirmPassword: null,
      },
    };
  },
  watch: {
    selectedPerson(newPerson) {
      this.form.email = newPerson.email;
    },
  },
  methods: {
    async loadAccessProfiles() {
      try {
        this.loadingAccessProfiles = true;

        const url = `/users/access-profiles`;

        this.accessProfiles = [];

        const response = await this.$axios.get(url);

        this.accessProfiles = response.data.map((p) => {
          return { value: p.id, text: p.name };
        });

        this.loadingAccessProfiles = false;
      } catch (error) {
        this.loadingAccessProfiles = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async confirmModal(title, msg, options) {
      return await this.$root.$confirm(title, msg, options);
    },
    save(actionAfterOperation) {
      const errors = this.validateForm();

      this.actionAfterOperation = actionAfterOperation;

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod]();
      }
    },
    prepareDataToInsert(data) {
      const copyOfData = { ...data };

      copyOfData.PersonId = this.selectedPerson.id;
      return copyOfData;
    },
    async insert() {
      try {
        this.loading = true;

        const url = `/users`;

        const dataToInsert = this.prepareDataToInsert(this.form);

        await this.$axios.post(url, dataToInsert);

        this.successDialog = true;
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    validateForm() {
      const errors = [];

      // console.log(this.form);

      if (!this.form.email) errors.push("O campo E-MAIL é obrigatório");

      if (!this.selectedPerson) errors.push("Vincule o usuário a uma pessoa!");

      if (!this.form.AccessProfileId)
        errors.push("Perfil de acesso não selecionado");

      if (this.mod === "insert") {
        if (this.form.password === "" || !this.form.password)
          errors.push("Senha obrigatória!");

        if (this.form.password !== this.form.confirmPassword)
          errors.push("confirmação de senha inválida!");
      } else if (this.mod === "update") {
        if (this.form.password && this.form.password.length) {
          if (this.form.password !== this.form.confirmPassword)
            errors.push("confirmação de senha inválida!");
        }
      }

      return errors;
    },
    prepareDataToUpdate(data) {
      const copyOfData = { ...data };

      delete copyOfData.PersonId;
      delete copyOfData.id;

      return copyOfData;
    },
    async update() {
      try {
        this.loading = true;

        const url = `/users/${this.userId}`;

        const dataToUpdate = this.prepareDataToUpdate(this.form);

        await this.$axios.put(url, dataToUpdate);

        this.successDialog = true;
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loading = false;
      }
    },
    async loadUserData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/users/${this.userId}`);

        this.setUserData(response.data);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setUserData(data) {
      const copyOfData = data;

      console.log(copyOfData);

      for (const property in copyOfData)
        if (!copyOfData[property]) delete copyOfData[property];

      this.selectedPerson = {
        fullName: copyOfData.Person.fullName,
        id: copyOfData.PersonId,
        email: copyOfData.email,
      };

      delete copyOfData.Person;
      delete copyOfData.PersonId;

      Object.assign(this.form, copyOfData);
    },
    closeSuccessDialog() {
      this.successDialog = false;

      if (this.mod === "insert") {
        this.afterInsertion(
          this.actionAfterOperation,
          {},
          {
            PersonId: null,
            AccessProfileId: null,
            password: null,
            email: null,
            confirmPassword: null,
          }
        );
      } else if (this.computedMode === "update") {
        this.afterUpdate(this.actionAfterOperation);
      } else {
        this.$emit("close-dialog");
      }
    },
  },
  computed: {
    userId() {
      return this.dialog ? this.databaseId : this.$route.params.id;
    },
  },
  created() {
    if (this.mod === "update") this.loadUserData();

    this.loadAccessProfiles();
  },
};
</script>

<style></style>
